

@tailwind base;
@tailwind components;
@tailwind utilities;

.px-10px {
    padding-left: 10px;
    padding-right: 10px;
}

html, body {
    overflow-x: hidden;
}
body {
    position: relative;
    direction: rtl;
    text-align: right;
    font-family: 'Rubik', sans-serif;
    width: 100%;
}

.prose h2,
.prose h3 {
    color: inherit;
    line-height: 1.2;
}

.carousel-items-center .slick-track {
    display: flex;
    align-items: center;
    text-align: center;
}

.last\:border-l-0:last-of-type {
    border-left: 0;
}

@media (min-width: 1024px) {
    body#body .lg\:last\:ml-0:last-of-type {
        margin-left: 0;
    }
    body#body .lg\:last\:border-l:last-of-type {
        border-left: 1px solid;
    }
}
@media (min-width: 1280px) {

    .xl\:max-w-978 {
        max-width: 978px;
    }
    .xl\:h-564 {
        height: 584px;
    }

}
@media (min-width: 1428px) {

    .\32xl\:max-w-screen-xl1\/2,
    .lg\:max-w-full.\32xl\:max-w-screen-xl1\/2 {
        max-width: 1428px;
    }
}

@media (min-width: 1024px) {
    .spinner {
        top: 30px;
        left: 0;
        position: relative;
    }
}

@media (min-width: 640px) {
    .flip {
        height: 28vw;
    }
}

@media (min-width: 1280px) {
    .flip {
        height: 328px;
    }
}

.flip-inner-flipped {
    transform: rotateY(180deg);
}

#about-me .prose p:first-child {
    margin-top: 0;
}

#about-me .prose p:last-child {
    margin-bottom: 0;
}

/* fix firefox nicescroll bug */
.scrollbar-thumb-rounded {
    overflow-y: scroll;
}

.gallery-section {
    max-height: 700px;
    overflow: hidden;
}